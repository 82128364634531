//input form to enter the token

import React, { useState } from 'react';

import api from '@Services/api';
import { useCookies } from 'react-cookie';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import {
    Spinner,

    ToastContainer,
    Toast,
} from 'react-bootstrap';
const ExpiredToken = ({
    redirect,
}) => {
    const [cookies, setCookie] = useCookies(['alani360']); // eslint-disable-line no-unused-vars
    const [showToast, setShowToast] = useState({ bg: 'success', show: false, message: '' });

    const [phone, setPhone] = useState("+" + cookies.alani360info?.phone);
    const [fetching, setFetching] = useState(false);

    const [selectedCountry, setSelectedCountry] = useState(cookies.alani360); // eslint-disable-line no-unused-vars

    const [linkSent, setLinkSent] = useState(false);

    const fnReactivate = () => {


        if (!phone
        ) {
            setShowToast({ bg: 'danger', show: true, message: 'Ingresa tu número de celular' });
            return;
        }

        let payload = {
            phone,
            country_code: selectedCountry,
            redirect: redirect
        }


        setFetching(true);


        api.reactivate({
            method: 'get',
            payload
        }).then(res => {
            console.warn(res);
            setFetching(false);
            if (res.success) {

                setLinkSent(true);
            } else {
                setShowToast({ bg: 'danger', show: true, message: res.message });
            }

        });
    }


    if (linkSent) {
        return (
            <div
                style={{
                    background: 'green',
                    textAlign: 'center',
                    width: 300,
                    margin: 'auto',
                    borderRadius: 5,
                }}
                className='d-flex flex-column justify-content-center align-items-center p-3 mt-3'
            >
                <p
                    className='text-white'
                >
                    Hemos enviado un mensaje de WhatsApp a tu número de celular con un enlace para continuar.
                </p>
            </div>
        )
    }

    return (
        <>
            <div
                className='d-flex justify-content-center align-items-center'

            >
                <ToastContainer
                    position='top-center'
                    style={{ marginTop: 120 }}
                    onClick={() => {
                        setShowToast({ bg: '', show: false, message: '' });
                    }}
                >
                    <Toast show={showToast.show}
                        style={{
                            maxWidth: 250,
                        }}
                        bg={showToast.bg}
                        onClose={() => {
                            setShowToast({ bg: '', show: false, message: '' });
                        }}
                    >
                        <Toast.Body
                            className='text-white text-center'
                        >
                            {showToast.message}
                        </Toast.Body>
                    </Toast>
                </ToastContainer>
            </div>
            <div
                style={{
                    background: '#F89A43',
                    textAlign: 'center',
                    width: 300,
                    margin: 'auto',
                    borderRadius: 5,
                }}
                className='d-flex flex-column justify-content-center align-items-center p-3 mt-3'
            >
                <p>
                    Ingresa tu número de celular para activar tu sesión.
                </p>
                <PhoneInput
                    labels={
                        {
                            "ZZ": "Internacional",
                            "CO": "Colombia",
                            "CR": "Costa Rica",
                            "MX": "México",
                            "US": "Estados Unidos",

                        }
                    }
                    style={{
                        fontSize: 16,
                        width: 180,
                    }}
                    countries={["MX", "US", "CO", "CR"]}
                    international
                    placeholder="Celular"
                    value={phone}
                    onChange={setPhone}
                    disabled={fetching}
                />
                <button
                    className="btn btn-primary"
                    onClick={() => fnReactivate()}
                    disabled={fetching}
                    style={{ width: '180px', marginTop: '1rem' }}
                >
                    {fetching && <Spinner className='me-2' animation="border" size='sm' />}
                    Activar Sesión
                </button>
            </div >

        </>
    );
}
export default ExpiredToken;
