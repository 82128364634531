import React, { useState } from 'react';
import Header from '@Components/HeaderPublic';
import HeaderAccount from '@Components/Header';
import { AsyncTypeahead } from 'react-bootstrap-typeahead'; // ES2015
import 'react-bootstrap-typeahead/css/Typeahead.css';

import { useDispatch, useSelector } from 'react-redux';
import { useCookies } from 'react-cookie';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import {
    Container, Spinner,
    Card,
    Form,
    Toast,
    ToastContainer,
    Tab,
    Tabs
} from 'react-bootstrap';

import { useParams, useSearchParams } from "react-router-dom";


import ExpiredToken from '@Components/ExpiredToken';

import api from '@Services/api';
//import { IoLinkOutline, IoChevronBack } from "react-icons/io5";

import moment from 'moment-timezone';
import 'moment/locale/es';
moment.tz.setDefault("America/Mexico");


const Results = ({ results }) => {

    let token = useParams().token;

    return <>

        <div
            className="d-flex flex-row justify-content-center align-items-center flex-wrap"
        >

            {
                results.map((result, index) => {
                    return <Card
                        key={index}
                        className='mt-3'
                    >
                        <Card.Img
                            className='mt-2'
                            style={{
                                maxWidth: 230,
                                maxHeight: 225,
                                width: 'auto',
                                height: 'auto',
                                margin: 'auto'
                            }}
                            variant="top"
                            src={`https://alani-images.s3.amazonaws.com/${result.sku}.png`}
                        />
                        <Card.Body>
                            <Card.Title>{result.name}</Card.Title>
                            <Card.Text>
                                {
                                    !!result.onSale &&
                                    <span
                                        className='badge bg-danger'
                                    >
                                        En oferta
                                    </span>
                                }
                            </Card.Text>
                            <a
                                rel="noreferrer"
                                target='_blank'
                                href={`/b/${token}/${result.sku}`}
                                className="btn btn-primary"
                            >
                                Ver Precio
                            </a>
                        </Card.Body>
                    </Card>
                }
                )
            }
        </div >
    </>
}


const SearchComponent = () => {
    const [showToast, setShowToast] = useState(false);

    const [cookies, setCookie] = useCookies(['alani360']); // eslint-disable-line no-unused-vars

    const [searchValue, setSearchValue] = React.useState('');
    const [sku, setSKU] = React.useState('');
    const [brand, setBrand] = React.useState('');
    const [onSale, setOnSale] = React.useState(false);
    const [refurbished, setRefurbished] = React.useState(false);
    const [newProduct, setNewProduct] = React.useState(false);

    const [fetching, setFetching] = React.useState(false);
    const [results, setResults] = React.useState([]);

    const [isLoading, setIsLoading] = useState(false);
    const [options, setOptions] = useState([]);
    const [noResults, setNoResults] = useState(false);


    const [key, setKey] = useState('home');

    const SEARCH_URI = 'https://h4gqwxd22m.execute-api.us-east-1.amazonaws.com/beta/bby/brands';
    const filterBy = () => true;
    const handleSearch = (query) => {
        setIsLoading(true);

        fetch(`${SEARCH_URI}?search=${query}`)
            .then((resp) => resp.json())
            .then((data) => {
                setOptions(data.brands);
                setIsLoading(false);
            });
    };

    const fnSearch = ({
        sku
    }) => {

        let body

        if (sku) {
            body = {
                sku,
                country_code: cookies.alani360,
                phone: '0'
            }
        } else {
            if (!searchValue) {
                setShowToast(true);
                return;
            }
            body = {
                description: searchValue,
                brand: brand,
                country_code: cookies.alani360,
                on_sale: onSale ? 1 : 0,
                refurbished: refurbished ? 1 : 0,
                newProduct: newProduct ? 1 : 0,
                phone: '0'
            }
        }






        setNoResults(false);
        setFetching(true);
        setResults([])
        api.search({
            method: 'POST',
            payload: {
                body
            }
        }).then(res => {

            setFetching(false);
            //console.warn(res);
            if (res.error) {
                return;
            }
            setNoResults(res.results.length === 0);
            setResults(res.results);

        });
    }




    return (<>
        <ToastContainer
            position='middle-center'
            onClick={() => {
                setShowToast(false);
            }}
        >
            <Toast show={showToast}
                style={{
                    maxWidth: 350,
                }}
                bg='danger'
                onClose={() => {
                    setShowToast(false);
                }} delay={3000} autohide
            >
                <Toast.Body
                    className='text-white text-center'
                >
                    Debes ingresar una descripción o marca
                </Toast.Body>
            </Toast>
        </ToastContainer>


        <Tabs
            id="controlled-tab-example"
            activeKey={key}
            onSelect={(k) => setKey(k)}
            className="mb-3"
        >
            <Tab eventKey="home" title="Buscar">
                <div
                    className="d-flex flex-column justify-content-center align-items-center "
                >
                    <div
                        className="d-flex flex-row justify-content-center align-items-center flex-wrap"
                    >
                        <div>

                            <label
                                htmlFor="search"
                            >
                                Marca
                            </label>
                            <AsyncTypeahead
                                disabled={fetching}
                                style={{ minWidth: "250px", maxWidth: '300px' }}
                                filterBy={filterBy}
                                id="brand-selector"
                                isLoading={isLoading}
                                labelKey="brand"
                                minLength={3}
                                onSearch={handleSearch}
                                options={options}
                                onChange={(e) => {
                                    if (e.length === 0) {
                                        setBrand('')
                                        return;
                                    }
                                    setBrand(e[0].brand)
                                }}
                                searchText="Buscando..."
                                placeholder="Selecciona una marca..."
                                renderMenuItemChildren={(option) => (
                                    <>
                                        <span>{option.brand}</span>
                                    </>
                                )}
                            />


                        </div>
                        <div>
                            <label
                                htmlFor="search"
                            >
                                Descripción
                            </label>
                            <input
                                disabled={fetching}
                                className="m-1 form-control"
                                type="text"
                                placeholder="Descripción"
                                value={searchValue}
                                onChange={(e) => setSearchValue(e.target.value)}

                                style={{ minWidth: "250px", maxWidth: '300px' }}
                            />
                        </div>



                    </div>
                    <div>
                        <div>
                            <Form.Check
                                type={`switch`}
                                id={`on_sale`}
                                label={`En Oferta`}
                                checked={onSale}
                                onChange={() => setOnSale(!onSale)}
                                disabled={fetching}
                            />


                            <Form.Check
                                type={`switch`}
                                id={`refurbished`}
                                label={`Reacondicionado`}
                                checked={refurbished}
                                disabled={fetching}
                                onChange={() => {
                                    setRefurbished(!refurbished);
                                    if (!refurbished) {
                                        setNewProduct(false);
                                    }
                                }}
                            />

                            <Form.Check
                                type={`switch`}
                                id={`newProduct`}
                                label={`Nuevo`}
                                checked={newProduct}
                                disabled={fetching}
                                onChange={() => {
                                    setNewProduct(!newProduct)
                                    if (!newProduct) {
                                        setRefurbished(false);
                                    }
                                }}
                            />
                        </div>
                    </div>
                    <button
                        className="btn btn-primary"
                        onClick={() => fnSearch({})}
                        disabled={fetching}
                        style={{ width: '120px', marginTop: '1rem' }}
                    >
                        {fetching && <Spinner className='me-2' animation="border" size='sm' />}
                        Buscar
                    </button>
                </div>

            </Tab>
            <Tab eventKey="profile" title="SKU">
                <div
                    className="d-flex flex-column justify-content-center align-items-center "
                >
                    <div>
                        <label
                            htmlFor="search"
                        >
                            SKU
                        </label>
                        <input
                            disabled={fetching}
                            className="m-1 form-control"
                            type="text"
                            placeholder="SKU"
                            value={sku}
                            onChange={(e) => setSKU(e.target.value)}

                            style={{ width: '200px' }}
                        />
                    </div>
                    <button
                        className="btn btn-primary"
                        onClick={() => fnSearch({
                            sku
                        })}
                        disabled={fetching}
                        style={{ width: '120px', marginTop: '1rem' }}
                    >
                        {fetching && <Spinner className='me-2' animation="border" size='sm' />}
                        Buscar
                    </button>
                </div>
            </Tab>
        </Tabs>

        {noResults ?
            <div
                className='d-flex justify-content-center pt-5'

            >

                <h5>No se encontraron resultados</h5>
            </div>
            :
            <Results results={results} />
        }

    </>
    )
}

function SearchScreen() {
    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams(); // eslint-disable-line no-unused-vars
    let token = useParams().token;


    const dataReducer = useSelector(state => state.dataReducer);
    const {
        account,
        fetching
    } = dataReducer;


    React.useEffect(() => {
        dispatch({
            type: 'ACCOUNT_FETCH',
            payload: {
                token,
            }
        })

    }, []); // eslint-disable-line react-hooks/exhaustive-deps


    return <>
        <HelmetProvider>
            <Helmet>
                <title>ALANI Search</title>
            </Helmet>
        </HelmetProvider>
        {!!token ?
            <HeaderAccount />
            :
            <Header />
        }
        <Container
            className='container-main mt-3'
        >
            {fetching === 'account_fetching' ?
                <div
                    className="mt-5 d-flex justify-content-center align-items-center"
                >
                    <Spinner animation="border" />
                </div>
                :
                <>
                    {account.error ?
                        <>
                            <ExpiredToken
                                redirect={`/[TOKEN]/buscar`}
                            />

                        </>

                        :
                        <SearchComponent />
                    }
                </>
            }

        </Container>
    </>
}

export default SearchScreen;
