
import {
    Container,
    Spinner,
    Button
} from 'react-bootstrap';

import { useTranslation, } from 'react-i18next';

import utils from '@Components/utils';
import { useCookies } from 'react-cookie';

import ExpiredToken from '@Components/ExpiredToken';
import { Link, useParams } from "react-router-dom";


const FetchingComponent = (props) => {
    const [cookies, setCookie] = useCookies(['alani360']); // eslint-disable-line no-unused-vars

    const { t } = useTranslation();

    const token = useParams().token;

    const LinkWhatsapp = ({
        text,
        link
    }) => <a
        target="_blank"
        rel="noreferrer"
        href={`https://wa.me/${utils.getWhatsappNumber(cookies.alani360)}?text=${utils.urlencode(link)}`}
    >
            {text}
        </a>

    return <>{props.fetching === 'cart_fetching' ?
        <Container
            className='justify-content-center align-items-center d-flex mt-5'

        >
            <Spinner animation="border" />
        </Container>
        :
        props.api_result?.error ?
            <Container
                className='mt-5'
                style={{
                    minWidth: 300,
                    maxWidth: 400,
                    textAlign: 'center',
                }}
            >
                {/*t(props.api_result?.message)*/}
                <div
                    className='mt-3'
                >
                    {
                        props.api_result.message === 'no_open_shopping_carts' &&
                        <>

                            <LinkWhatsapp
                                text="Tu carrito de compras está vacío"
                                link={"Hola, quiero hacer un pedido"}
                            />
                            <br /><br />
                            <Button>
                                <Link
                                    style={{ color: 'white', textDecoration: 'none' }}
                                    to={`/${token}/buscar`}
                                >
                                    {t('Buscar Productos')}
                                </Link>
                            </Button>
                        </>
                    }
                    {
                        props.api_result.message === 'invalid_token' &&
                        <ExpiredToken
                            redirect={`/[TOKEN]/pay`}
                        />
                    }

                </div>
            </Container>
            :
            <>
                {props.children}

            </>
    }
    </>
}

export default FetchingComponent;
